.table1 {
    width: 100%;
    border-collapse: collapse; /* Collapse borders for a cleaner look */
}

.table1 td, .table1 th {
    border: 1px solid lightgray; /* Add borders to all cells */
    padding-left: 5px; /* Add some padding for better readability */
    text-align: left;
}

.formFont {
    font-family: 'Times New Roman', Times, serif !important;
    font-size: 10pt !important;
}

.header {
    font-weight: bold;
}

.table2 {
    width: 100%;
    border-collapse: collapse; /* Collapse borders for a cleaner look */
    table-layout: fixed; /* Ensures equal column width */
}

.table2 td {
    padding-top: 0
}

.table3 {
    width: 100%;
    table-layout: fixed;
}
.table3 td {
    border: none;
    width: 33.33%; /* Set each column to roughly 33.33% width */
}

.table4 {
    width: 100%;
    border-collapse: collapse; /* Collapse borders for a cleaner look */
    margin-top: 20px;
}

.table4 td, .table4 th{
    border: 1px solid lightgray; /* Add borders to all cells */
    padding: 0;
    width: 25% !important; /* Set each column to roughly 25% width */
    text-align: center;
}

.date-changeable {
    width: 30px !important;
    border: none !important;
    padding: 0 4px 0 4px !important;
    font-family: 'Times New Roman', Times, serif !important;
    font-size: 10pt !important;
    font-weight: bold;
}

.year {
    width: 50px !important;
}

.name, .department {
    width: 100% !important;
    margin-top: 0;
}

.input-dotted-border {
    border-bottom: 1px dotted black !important;
    border-radius: 0 !important;
    margin-bottom: 5px;
    width: 90% !important;
}

.print-only {
    display: none;
}

@media print {
    .form-draft-print {
        overflow: visible !important;
        height: 100% !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .print-only {
        display: block !important;
    }
}

.table-dark-border > td {
    padding: 3px !important;
}

.compact-table > td {
    padding: 5px !important;
}

.compact-table > td, .ant-table-title {
    padding: 10px !important;
}

.formFont > th {
    text-align: center !important;
    text-wrap: nowrap !important;
}
