
.text-center {
    text-align: center !important;
}

.hand {
    cursor: pointer !important;
}

.link-text:hover {
    color: #007bff !important;
    cursor: pointer !important;
}

.items-start-end {
    justify-content: space-between !important;
    width: 100% !important;
    display: inline-flex !important;
}

.text-xs {
    font-size: 9pt !important;
}

.text-s {
    font-size: 10pt !important;
}

.text-l {
    font-size: 14pt !important;

}

.text-xl {
    font-size: 16pt !important;
}

.text-xxl {
    font-size: 18pt !important;
}

.text-bold {
    font-weight: 600 !important;
}

.border-bottom {
    border-bottom: 1px solid #f0f0f0 !important;
}

.pb-1 {
    padding-bottom: 0.5rem !important;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.text-blinking {
    animation: blink 1s infinite !important;
}

th.ant-table-cell {
    overflow-wrap: inherit !important;
}

tfoot.tr.td.ant-table-cell {
    overflow-wrap: inherit !important;
}

.drag-item {
    display: flex;
    padding: 8px;
    margin: 0 0 8px 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    justify-content: space-between;
}

.drag-item:hover {
    cursor: grab;
}

.drag-item:active {
    opacity: 0.5;
    font-weight: 600;
    border: 1px solid coral;

    cursor: grabbing;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.4);
}

.offscreen-input {
    position: absolute;
    left: -9999px; /* Move the input offscreen horizontally */
    top: 0; /* Keep it at the top of the viewport */
    width: 1px; /* Set a minimal width */
    height: 1px; /* Set a minimal height */
    overflow: hidden; /* Hide any overflowing content */
}

.hr-style-fade {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.hr-style {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.travel-expense-step-header {
    font-weight: 700;
}

.narrow-form-item {
    margin-bottom: 10px !important;
}

.travel-expense-body-content {
    height: 330px;
    max-height: 330px;
    overflow-y: auto;
    overflow-x: hidden;
}

.ant-table-expand-icon-col {
    width: 1px !important;
}

.ant-modal-mask {
    touch-action: none !important;
    -ms-touch-action: none !important;
    -webkit-touch-callout: none !important;
}

.scroll-disabled {
    overflow: hidden;
    position: fixed;
}

.ant-result-icon .anticon{
    font-size: 40px !important;
}
.equal-width-radio-group .ant-radio-button-wrapper {
    flex: 1 !important;
}

.ant-radio-group-solid {
    display: flex !important;
}

#money_total {
    color: crimson!important;
    font-weight: 700 !important;
}

.toast-box-separator {
    height: 100% !important;
    border-inline-start: rgba(255, 255, 255, 0.1) 1px solid !important;
}